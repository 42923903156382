import React, { useEffect } from 'react'
import { useContext } from '~/hooks'
import { PageProps } from 'gatsby'
import { Head, Login } from '~/components'

const PasswordRequired = ({ location }: PageProps) => {
  // Teste do componente de loading
  // const { setLoading } = useContext()
  // useEffect(() => {
  //   setLoading(true)
  //   setTimeout(() => setLoading(false), 2000)
  // }, [])

  return (
    <>
      <Head location={location} />
      <main className="bkg-main">
        <Login
          title="Solicitar acesso"
          input={[
            {
              label: 'CNPJ',
              name: 'login',
              mask: 'cnpj',
            },
          ]}
          btnRequest="Solicitar senha"
          btnLoading="Enviando..."
          nextPage="senha-enviada"
          location={location}
          path={'request-new-password'}
        />
      </main>
    </>
  )
}

export default PasswordRequired
